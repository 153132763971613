<template>
  <div class="layout-vertical">
    <background></background>
    <notification-bar></notification-bar>
    <div class="layout-horizontal margin-left margin-large" style="margin-top: 64px;" v-if="account">
      <user-avatar class="image-avatar image-large" :user="account">
      </user-avatar>
      <span class="margin-left-small font-align-left">
        <div class="font-size-extra-large">{{ account.account }}</div>
        <div class="font-color-secondary">{{ date }}</div>
      </span>
    </div>
    <div class="divider divider-dark margin-left-large margin-top"></div>
    <div class="font-color-secondary font-align-left margin-left-large margin-top">选择组织</div>
    <div class="layout-horizontal layout-wrap">
      <div class="card card-organization margin-left-large margin-top padding layout-horizontal layout-middle"
           v-for="organization in organizationList" :key="organization.id"
           @click="handleOrganizationCardClick(organization)">
        <el-image class="image-logo" :src="$prepareImageUrl(organization.avatar)"></el-image>
        <span class="font-align-left margin-left">
          <div class="font-size-medium">{{ organization.name }}</div>
          <div class="font-color-secondary">{{ organization.introduction }}</div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationBar from "../../components/notificationbar/NotificationBar";
import httpapi from "@/assets/javascript/httpapi";
import {TimeUtils} from "@/assets/javascript/time-utils";
import OrganizationUtils from "@/assets/javascript/organization-utils";
import Background from "@/components/background/Background";
import UserAvatar from "@/components/user/UserAvatar";

function doLoadOrganziationList(pageNum) {
  if (this.isLoading) {
    return;
  }
  this.isLoading = true;
  console.log('load organization list');
  this.$reqGet({
    path: '/organization/list/get/by_account',
    data: {
      pageNum,
      pageSize: this.pageSize,
    }
  }).then(res => {
    let organizationList = this.organizationList;
    this.$appendAfter(organizationList, res.data.list);
    this.organizationList = organizationList;
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
  }).catch(() => {
    this.$message.error('加载失败');
  }).complete(() => {
    this.isLoading = false;
  })

}

export default {
  name: "OrganizationChoosePage",
  mixins: [httpapi],
  components: {UserAvatar, Background, NotificationBar},
  data() {
    return {
      account: null,
      date: null,
      organizationList: [],
      pageNum: 1,
      pageSize: 20,
      totalPages: 0,
      isLoading: false,
    }
  },
  methods: {
    loadOrganizationList(pageNum = 1) {
      doLoadOrganziationList.bind(this)(pageNum);
    },
    handleOrganizationCardClick(organization) {
      OrganizationUtils.$setCurrentOrganization(organization);
      this.$router.push({name: 'users'})
    }
  },
  mounted() {
    this.account = this.$getAccount();
    this.date = TimeUtils.format('yyyy年MM月dd日', new Date());
    this.loadOrganizationList();
  }
}
</script>

<style scoped>

.divider {
  width: 500px;
}

.card-organization {
  cursor: pointer;
  min-width: 400px;
}

.card-organization:hover {
  opacity: 0.8;
}

</style>